
import { defineComponent } from "vue";

import {
  ShiftToText,
} from "@/components"

export default defineComponent({
  props: ['order'],
  components: {
    ShiftToText,
  }
})
