
import { UserType } from '@/model/Entity';
import { datetimeFormat } from '@/utils/filters';
import { computed, defineComponent, toRef } from 'vue';

import {
  ShiftToText
} from "@/components"
import { useI18n } from 'vue-i18n';
import { stringDuration } from '../../common';

export default defineComponent({
  components: {
    ShiftToText
  },

  props: {
    order: {
      type: Object,
      required: true,
    },
    showWarningTime: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },

  setup(p, {emit}) {
    const { t } = useI18n();
    const order = toRef(p, 'order');

    const pickup_priority   = 
      computed(() => order.value.pickup_priority);

    const dropoff_priority  = 
      computed(() => order.value.dropoff_priority);

    const creationDateTime  = 
      computed(() => 
        datetimeFormat(
          order.value.created_at, 
          "dddd  D MMMM YYYY [alle] HH:mm"
        )
      );

    const executionDatetime  = 
      computed(() => 
        datetimeFormat(
          order.value.pickup_datetime, 
          "dddd  D MMMM YYYY"
        )
      );

    const warningTime = 
      computed(() => {
        const e = (new Date(order.value.pickup_datetime)).valueOf();
        const c = (new Date()).valueOf();

        const diff = e - c;

        return stringDuration(
          Math.floor(diff / 1000 / 60),
          t
        )
      });

    const pickupTime  =
      computed(() => 
        datetimeFormat(
          order.value.pickup_datetime, 
          "HH:mm"
        )
      );

    const dropoffTime  =
      computed(() => 
        datetimeFormat(
          order.value.dropoff_datetime, 
          "HH:mm"
        )
      );

    const supplierValue = UserType.SUPPLIER;
    const takerValue = UserType.TAKER;

    const clientCode = computed({
      get(){
        return order.value.external_client_code;
      },
      set(v: string){
        emit('update:order', {
          ...order.value,
          external_client_code: v,
        });
      }
    })
    return {
      clientCode,
      creationDateTime,
      executionDatetime,
      pickupTime, 
      dropoffTime,
      pickup_priority,
      dropoff_priority,
      supplierValue,
      takerValue,
      warningTime
    }
  }
})
