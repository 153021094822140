
import type { Order }       from "../../../../model/Order";
import { OrderStatusEnum }  from "../../../../model/enums/OrderStatusEnum";

import { computed, defineComponent, PropType } from "vue";
import { MenuItem } from "primevue/menuitem";

export default defineComponent({
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true
    },
  },

  setup(p, { emit }) {
    const status = computed(() => (p.order as Order).status);

    const isREJECTED    = computed(() => status.value === OrderStatusEnum.RIFIUTATO);
    const isACCEPTED    = computed(() => status.value === OrderStatusEnum.ACCETTATO);
    const isASSIGNED    = computed(() => status.value === OrderStatusEnum.ASSEGNATO);
    const isW_PICKUP    = computed(() => status.value === OrderStatusEnum.ATTESA_RITIRO);
    const isPICKED_UP   = computed(() => status.value === OrderStatusEnum.RITIRATO);
    const isW_DROPOFF   = computed(() => status.value === OrderStatusEnum.ATTESA_CONSEGNA);
    const isDROPPED_OFF = computed(() => status.value === OrderStatusEnum.CONSEGNATO);
    const isCANCELED    = computed(() => status.value === OrderStatusEnum.ANNULLATO);

    const orderActionsMenu = computed(() => ([
      {
        label: 'Accetta',         
        command: () => emit('accept'),
        value: OrderStatusEnum.ACCETTATO,
      },
      {
        label: 'Rifiuta',         
        command: () => emit('reject'),
        value: OrderStatusEnum.RIFIUTATO,
      },
      {
        label: 'Assegna',         
        command: () => emit('assign'),
        value: OrderStatusEnum.ASSEGNATO,
      },
      {
        label: 'Attesa ritiro',   
        command: () => emit('waitingPickup'),
        value: OrderStatusEnum.ATTESA_RITIRO,
      },
      {
        label: 'Ritirato',        
        command: () => emit('pickedUp'),
        value: OrderStatusEnum.RITIRATO,
      },
      {
        label: 'Attesa consegna', 
        command: () => emit('waitingDropoff'),
        value: OrderStatusEnum.ATTESA_CONSEGNA,
      },
      {
        label: 'Consegnato',      
        command: () => emit('droppedOff'),
        value: OrderStatusEnum.CONSEGNATO,
      },
      {
        label: 'Annulla',         
        command: () => emit('cancel'),
        value: OrderStatusEnum.ANNULLATO,
      },
    ] as MenuItem[]).filter( ({value}) => value !== status.value ));

    return {
      isREJECTED,
      isACCEPTED,
      isASSIGNED,
      isW_PICKUP,
      isPICKED_UP,
      isW_DROPOFF,
      isDROPPED_OFF,
      isCANCELED,

      orderActionsMenu
    }
  }
})
