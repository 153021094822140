import { Order }           from '@/model/Order';
import { CRUD }            from './base/crud';


class PaymentService extends CRUD<Order, any> {
    readonly endPoint = `order/payment`;

    public checkoutSession(order_id: number): Promise<{session_id: string}> {
        return this.post(
            `${this.endPoint}/checkout-session`,
            { order_id }
        );
    }

    public refund(order_id: number) {
      return this.post(
        `${this.endPoint}/refund`,
        { order_id }
      )
    }
}

export const paymentService = new PaymentService();



