import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-1da2cb78")
const _hoisted_1 = { class: "toolbar__left" }
const _hoisted_2 = { class: "date_range" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_CustomerAutocomplete = _resolveComponent("CustomerAutocomplete")!
  const _component_FloatLabel = _resolveComponent("FloatLabel")!
  const _component_ZoneAutocomplete = _resolveComponent("ZoneAutocomplete")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createBlock(_component_Panel, {
    class: "p-mb-3",
    header: _ctx.$t('common.advanced_filters'),
    toggleable: true,
    collapsed: false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.hasOrderDate)
            ? (_openBlock(), _createBlock(_component_Calendar, {
                key: 0,
                modelValue: _ctx.filters.from_to,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.filters.from_to = $event)),
                manualInput: false,
                showIcon: true,
                selectionMode: "range",
                onDateSelect: _ctx.updateFilters
              }, null, 8, ["modelValue", "onDateSelect"]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.hasCustomerId)
          ? (_openBlock(), _createBlock(_component_FloatLabel, {
              key: 0,
              label: "Cliente",
              class: "p-fluid"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_CustomerAutocomplete, {
                  modelValue: _ctx.filters.customer,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.filters.customer = $event)),
                  onResultSelected: _ctx.updateFilters
                }, null, 8, ["modelValue", "onResultSelected"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.hasZoneId)
          ? (_openBlock(), _createBlock(_component_FloatLabel, {
              key: 1,
              label: "Città",
              class: "p-fluid"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ZoneAutocomplete, {
                  modelValue: _ctx.filters.zone,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.filters.zone = $event)),
                  onResultSelected: _ctx.updateFilters
                }, null, 8, ["modelValue", "onResultSelected"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["header"]))
}