import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-mt-3" }
const _hoisted_2 = { class: "p-mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_router_link, { to: _ctx.orderRoute }, {
        default: _withCtx(() => [
          _createVNode(_component_Button, {
            style: {"width":"250px"},
            class: "p-button-info",
            icon: "pi pi-eye",
            label: "Vai al dettaglio dell'ordine"
          })
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_link, { to: _ctx.newOrderRoute }, {
        default: _withCtx(() => [
          _createVNode(_component_Button, {
            style: {"width":"250px"},
            icon: "pi pi-plus",
            label: "Crea un altro ordine"
          })
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, { to: _ctx.dashboard }, {
        default: _withCtx(() => [
          _createVNode(_component_Button, {
            style: {"width":"250px"},
            class: "p-button-secondary",
            icon: "pi pi-home",
            label: "Torna alla dashboard"
          })
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ], 64))
}