
  import { PickerSummaryModel } from "@/model/Entity";
  import { Options, Vue } from "vue-class-component";
  import { Prop } from "vue-property-decorator";

  @Options({})
  export default class PickerSummary extends Vue {
    @Prop() readonly picker!: PickerSummaryModel;
    @Prop() readonly savePicker!: Boolean;
    @Prop() readonly type!: String;
    @Prop({default: false}) readonly hideSave!: boolean;
  }
