
import { computed, defineComponent, Ref, toRef } from 'vue'
import { OrdersRoutesEnum } from '../../router';

export default defineComponent({
  props: ['orderId'],

  setup(p) {
    const orderId: Ref<number> = toRef(p, "orderId");
    const dashboard = { name: OrdersRoutesEnum.ORDERS_LIST };

    const orderRoute = computed(() => ({
      name: OrdersRoutesEnum.ORDERS_DETAIL,
      params: { orderId: orderId.value }
    }))

    const newOrderRoute = {
      name: OrdersRoutesEnum.ORDERS_NEW_ZONE
    }

    return {
      dashboard,
      orderRoute,
      newOrderRoute
    }
  },
})
