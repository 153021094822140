import { Prop, Options, Vue } from "vue-property-decorator";
import { FilterMatchMode }    from "primevue/api";

import { UserType }      from "@/model/Entity";
import { BaseApiFilter } from "@/model/filters/BaseApiFilter";
import { Supplier }      from "@/model/Supplier";
import { Taker }         from "@/model/Taker";

import { suppliersService } from "@services/suppliers.service";
import { takersService }    from "@services/takers.service";

export interface AssignmentPayload {
  supplier: Supplier;
  entityType: UserType;
  taker: Taker; 
}

@Options({})
export default class OrderAssignmentDialog extends Vue {
  @Prop() readonly zoneId!: number;

  @Prop({ default: false}) 
  readonly allowEnterTaker!: boolean;
  
  entityType       : UserType          = null;

  searchBox   : string | Supplier | Taker = null;
  suggestions : Supplier[] | Taker[]      = null;
  selected    : Supplier | Taker          = null;

  get takerValue() {
    return UserType.TAKER;
  }
  
  get supplierValue() {
    return UserType.SUPPLIER;
  }

  takerLabel(taker: Taker) {
    return `${taker.name} ${taker.surname}`;
  }

  supplierLabel(supplier: Supplier) {
    return `${supplier.business_name} (${supplier.name} ${supplier.surname})`;
  }

  onTypeChange() {
    this.selected = null; 
    this.searchBox  = null;
  }

  onHide() {
    this.searchBox  = null;
    this.selected   = null;
    this.entityType   = null; 
  }
  
  onSelect({ value }) {
    this.selected = value;
  }

  onConfirm() {
    
    if (this.entityType === this.supplierValue && !this.selected) {
      this.$toast.add({
        severity: 'error',
        summary: 'Errore',
        detail: 'Seleziona un fornitore',
        life: 3000,
        group: 'this_dialog'
      });

      return;
    }

    this.$emit('confirm', {
      entityType : this.entityType,
      supplier   : this.entityType === this.supplierValue ? this.selected : null,
      taker      : this.entityType === this.takerValue    ? this.selected : null,
    } as AssignmentPayload);
  }

  async search({ query }) {
    const f = new BaseApiFilter();
    f.q = query;

    if (this.zoneId) {
      f.filters = {
        "zones.id": {
          value: this.zoneId,
          matchMode: FilterMatchMode.EQUALS
        }
      }
    }

    const response = await suppliersService.index(f);

    this.suggestions = response.data;
  }

  async searchTaker({ query }) {
    const f = new BaseApiFilter();
    f.q = query;

    if (this.zoneId) {
      f.filters = {
        "zones.id": {
          value: this.zoneId,
          matchMode: FilterMatchMode.EQUALS
        }
      }
    }
    const response = await takersService.index(f);

    this.suggestions = response.data;
  }

}