import moment from "moment";

export function stringDuration(minutes: number, $t) {
  if (minutes) {
    const dx = moment.duration(
      minutes,
      "minutes"
    );

    const d = Math.floor(dx.days());
    const h = Math.floor(dx.hours());
    const m = Math.floor(dx.minutes());
    const s = Math.floor(dx.seconds());

    const dtext = $t('common.days', h).trim();
    const htext = $t('common.hours', h).trim();
    const mtext = $t('common.minutes', m).trim();
    const stext = $t('common.seconds', s).trim();

    return (d ? ` ${d} ${dtext}` : '') + 
      (h ? ` ${h} ${htext}` : '') +
      (m ? ` ${m} ${mtext}` : '') + 
      (s ? ` ${s} ${stext}` : '');
  } else {
    return " -- "
  }
}