import { Order }          from "@/model/Order";
import OverlayPanel       from "primevue/overlaypanel";
import { Options, Vue }   from "vue-class-component";
import { Prop }           from "vue-property-decorator";
import { stringDuration } from "../../common";

import StepDetails from "./StepDetails/StepDetails.vue";

@Options( {
  components: {
    StepDetails
  }
} )
export default class OrderStatusDetails extends Vue {
  @Prop() readonly order!: Order;

  showSignature: boolean = false;

  get timeTravel() {
    return stringDuration(this.order?.time_travel, this.$t)
  }

  get timePickup() {
    return stringDuration(this.order?.time_waiting_pickup, this.$t)
  }

  get timeDropoff() {
    return stringDuration(this.order?.time_waiting_dropoff, this.$t)
  }

  get pickupDateTime() {
    return this.order?.pickup_real_datetime;
  }

  get dropoffDateTime() {
    return this.order?.dropoff_real_datetime;
  }

  get pickupNote() {
    return this.order?.pickup_note;
  }

  get dropoffNote() {
    return this.order?.dropoff_note;
  }

  get pickupImage() {
    return this.order?.pickup_image_url;
  }

  get dropoffImage() {
    return this.order?.dropoff_image_url;
  }

  get hasSignature() {
    return !!this.order?.dropoff_signature;
  }

  get ETA() {
    return this.order?.eta;
  }

  get KM() {
    return this.order?.km_road;
  }

  get createdAt() {
    return this.order?.created_at;
  }

  get executionDatetime() {
    return new Date( this.order.dropoff_datetime );
  }

  get creationExecutionDelta() {
    const e = this.executionDatetime.valueOf()
    const c = this.createdAt.valueOf()

    const diff = e - c;

    return stringDuration(
        Math.floor( diff / 1000 / 60 ),
        this.$t
    )
  }

  get googleMapsUrl() {
    const { dropoff_lat, dropoff_lon, pickup_lat, pickup_lon } = this.order;
    return `https://www.google.com/maps/dir/?api=1&origin=${ pickup_lat },${ pickup_lon }&destination=${ dropoff_lat },${ dropoff_lon }`;
  }

  toggleSignature( event ) {
    (this.$refs.signatureOP as OverlayPanel).toggle( event );
  }
}
