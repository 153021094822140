
import { computed, defineComponent, onMounted, Ref, ref, toRef } from 'vue'
import { loadStripe } from '@stripe/stripe-js/pure';
import { Stripe } from '@stripe/stripe-js';
import { configuration } from '@plugins/Config-plugin';
import { Order, PaymentStatusEnum } from '@/model/Order';
import { PaymentType } from '@/model/Entity';
import { paymentService } from '@services/payment.service';
import { useMessage } from '@plugins/Messages-plugin';

export default defineComponent({
  props: ['order'],

  setup(p) {
    const { errorMessage } = useMessage();
    const order: Ref<Order> = toRef(p, 'order');
    const stripe = ref<Stripe>(null);
    const loading = ref(false);

    async function initStripe() {
      stripe.value = await loadStripe(configuration.stripeKey);
    }

    const isAnticipated = computed(() => {
      return order.value.payment_type === PaymentType.ANTICIPATED;
    })

    const hasToPay = computed(function() {
      return isAnticipated.value
        &&  order.value?.payment_status  === PaymentStatusEnum.TO_PAY
        &&  order.value.payment_amount > 0; 
    });
    
    async function checkout() {
      try {
        loading.value = true; 
        const { session_id: sessionId } = 
          await paymentService.checkoutSession(order.value.id);
  
        localStorage.setItem('payment_order', order.value.id.toString());
  
        stripe.value.redirectToCheckout({sessionId})
          .then(alert);
      } catch (error) {
        errorMessage("Checkout fallito");
      } finally {
        loading.value = true; 
      }
    }

    onMounted(() => {
      if (isAnticipated.value && hasToPay.value) {
        initStripe();
      }
    })

    return {
      loading,
      stripe,
      checkout,
      isAnticipated,
      hasToPay
    }
  },
})
