
import { Order, PaymentStatusEnum }       from "../../../../model/Order";
import { OrderStatusEnum }  from "../../../../model/enums/OrderStatusEnum";

import { computed, defineComponent, onMounted, Ref, ref, toRef } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    order: {
      required: true
    },
  },

  setup(p) {
    const { t } = useI18n();
    const layout = ref<"horizontal"|"vertical">('horizontal');
    const align = ref<any>('bottom');
    const order = (toRef(p, 'order')) as Ref<Order>;

    const statusSequence = [
      OrderStatusEnum.ACCETTATO,
      OrderStatusEnum.ASSEGNATO,
      OrderStatusEnum.ATTESA_RITIRO,
      OrderStatusEnum.RITIRATO,
      OrderStatusEnum.ATTESA_CONSEGNA,
      OrderStatusEnum.CONSEGNATO,
    ];

    const doneIcon  = 'pi pi-check-circle green';
    const doingIcon = 'pi pi-circle-on green';
    const todoIcon  = 'pi pi-circle-on red';
    const xIcon     = 'pi pi-times-circle red';

    const timelineData = computed(() => {
      const {
        status
      } = (order.value as Order);

      const currentIdx = statusSequence.indexOf(status);

      function getIcon(s) {
        if ([
              OrderStatusEnum.ANNULLATO,
              OrderStatusEnum.RIFIUTATO,
            ].includes(status)
        ) { return xIcon; }
        

        if (status === OrderStatusEnum.CONSEGNATO) {
          return doneIcon;
        }

        const idx = statusSequence.indexOf(s);
        return idx === currentIdx 
          ? doingIcon 
          : idx < currentIdx 
            ? doneIcon 
            : todoIcon;
      }

      return statusSequence.map(s => ({
        label: t(`order.status_${s}`),
        icon: getIcon(s),
        isActive: s === status
      }));
    })

    const isRefunded = computed(() => {
      return order.value?.payment_status === PaymentStatusEnum.REFUNDED;
    })

    onMounted(() => {
      const iw = window.innerWidth;
      layout.value  = iw >= 577 ? 'horizontal'  : 'vertical';
      align.value   = iw >= 577 ? 'bottom'      : 'alternate';

      const mql = window.matchMedia('(max-width: 576px)');

      mql.onchange = (e: MediaQueryListEvent) => {
        if (e.matches) {
          layout.value = "vertical";
          align.value = "alternate";
        }else{
          layout.value = "horizontal";
          align.value = "bottom";
        }
      }
    })

    return {
      isRefunded,
      timelineData,
      layout,
      align
    }
  }
})
