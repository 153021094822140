import moment, { HTML5_FMT } from "moment";

/**
 * Formatta la data nel formato passato da parametro
 * per default nel formato italiano "DD-MM-YYYY"
 * @param value Data non formattata
 * @param format Formato desiderato
 */
export function datetimeFormat(value: any, format: string = "DD-MM-YYYY HH:mm") {
    if (!value) return "";
    return moment(value).format(format);
}

export function htmlTimeFormat(value: string, format: string = "HH:mm") {
    if (!value) return "";
    return moment(value, HTML5_FMT.TIME).format(format);
}

export function currencyFormat(value: number, symbol: string = '€') {
    return (value !== undefined) && (value !== null) && !isNaN(value)
    ? `${value.toFixed(2)} ${symbol}` 
    : '';
}