import { Order } from "@/model/Order";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

export declare class IOrderNotes {
  $props: {
    order       : Order;
    isReadonly? : boolean;
  }
}

@Options({})
export default class OrderNotes extends Vue {
  @Prop() readonly order!: Order;

  @Prop() readonly isReadonly!: boolean;
}