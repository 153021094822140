import { Options, Vue }     from "vue-class-component";
import { Model, Prop }      from "vue-property-decorator";
import moment               from "moment";
import { OrdersAdvFilters } from "@/modules/orders/mixins/OrderTable";

import { CustomerAutocomplete, ZoneAutocomplete } from "@/components"

@Options( {
  components: {
    CustomerAutocomplete,
    ZoneAutocomplete,
  }
} )
export default class OrderFilters extends Vue {
  @Prop() readonly schema!: any;

  /**
   * If {@link schema} contains "order_date"
   * DateRange filter will be visible
   */
  get hasOrderDate() {
    return this.schema?.order_date;
  }

  /**
   * If {@link schema} contains "customer_id"
   * CustomerAutocomplete filter will be visible
   */
  get hasCustomerId() {
    return this.schema?.customer_id;
  }

  /**
   * If {@link schema} contains "zone_id"
   * ZoneAutocomplete filter will be visible
   */
  get hasZoneId() {
    return this.schema?.zone_id;
  }

  @Model('modelValue')
  filters: OrdersAdvFilters;

  get calendar() {
    return (this.$refs as any).op;
  }

  get fromDate() {
    const from = this.filters?.from_to?.[0];

    return from
      ? moment(from).format('DD/MM/YYYY')
      : "--/--/----";
  }

  get toDate() {
    const to = this.filters?.from_to?.[1];

    return to
      ? moment(to).format('DD/MM/YYYY')
      : "--/--/----";
  }

  openCalendar(event) {
    this.calendar?.toggle(event);
  }

  hideCalendar() {
    this.calendar?.hide();
  }

  updateFilters() {
    this.$emit('update', this.filters);
  }

}
