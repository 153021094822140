import moment from "moment";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({})
export default class StepDetails extends Vue {
  @Prop() readonly title!: String;

  @Prop() readonly note!: String;

  @Prop() readonly time!: String;

  @Prop() readonly date!: Date;

  @Prop() readonly imagePath!: string;

  get dateString() {
    return this.date 
    ? moment(this.date).format("DD MMMM YYYY [alle] HH:mm")
    : '--';
  }

}