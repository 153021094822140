
import { Order, PaymentStatusEnum } from "@/model/Order";
import { Options, Prop, Vue }       from "vue-property-decorator";

@Options( {} )
export default class PriceAndCostSummary extends Vue {
  @Prop() readonly order: Order;
  @Prop() readonly isReadonly: boolean;
  @Prop() readonly costExtraReadonly: boolean;

  get logPrice() {
    return this.order?.calc_log_price;
  }

    get logCost() {
      return this.order?.calc_log_cost;
    }

  get isPaid() {
      return this.order?.payment_status === PaymentStatusEnum.PAID;
    }
  }
