
  import { Customer } from "@/model/Customer";
  import { Vue, Options, Prop } from "vue-property-decorator";

  @Options({})
  export default class CustomerSummary extends Vue {
    @Prop()
    readonly customer!: Customer;
    @Prop()
    readonly orderCode!: string;
  }
